import {
  Box,
  Button,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  InputLabel,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { uploadFileAPI } from "../../../../api/APIBanners";
import { IAssetItems } from "../../../../models/IAssets";
import { AWS_URL, useContentUrls } from "../../../../configs/constants";
import { useState } from "react";

interface ITasteBudFormProps {
  tasteBudItem: IAssetItems;
  removeTasteBudItem: (id: string) => void;
  updateTasteBudItem: (tasteBudItem: IAssetItems, id: string) => void;
  tasteBudId: string;
}

const TasteBudForm = ({
  tasteBudItem: initialTasteBudItem,
  removeTasteBudItem,
  updateTasteBudItem,
  tasteBudId,
}: ITasteBudFormProps) => {
  const [tasteBudItem, setTasteBudItem] =
    useState<IAssetItems>(initialTasteBudItem);
    const contentUrls = useContentUrls();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      if (selectedFiles) {
        try {
          const file = selectedFiles[0];
          const uploadedFile = await uploadFileAPI(file);
          const updatedItem = {
            ...tasteBudItem,
            asset: uploadedFile,
          };
          setTasteBudItem(updatedItem);
          updateTasteBudItem(updatedItem, tasteBudId);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    }
  };

  const handleTextChange = (value: string) => {
    const updatedItem = {
      ...tasteBudItem,
      text: value,
    };
    setTasteBudItem(updatedItem);
    updateTasteBudItem(updatedItem, tasteBudId);
  };

  const handleButtonTextChange = (value: string) => {
    const updatedItem = {
      ...tasteBudItem,
      btn_text: value,
    };
    setTasteBudItem(updatedItem);
    updateTasteBudItem(updatedItem, tasteBudId);
  };

  const handleButtonUrlChange = (value: string) => {
    const updatedItem = {
      ...tasteBudItem,
      btn_url: value,
    };
    setTasteBudItem(updatedItem);
    updateTasteBudItem(updatedItem, tasteBudId);
  };

  return (
    <Paper sx={{ p: 2, width: "100%", position: "relative" }}>
      <Box sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}>
        <IconButton
          onClick={() => removeTasteBudItem(tasteBudId)}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            },
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </Box>
      <Stack direction="column" spacing={1} alignItems="center">
        <Box
          sx={{
            width: 150,
            height: 150,
            border: "1px dashed #ccc",
            borderRadius: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            cursor: "pointer",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {tasteBudItem.asset ? (
            <>
              <img
                src={`${AWS_URL}/${tasteBudItem.asset}`}
                alt="Uploaded"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
              <Button
                variant="contained"
                component="label"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
                Change Image
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              component="label"
              sx={{
                height: "100%",
                width: "100%",
                borderRadius: 1,
              }}
            >
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
              <Stack spacing={1} alignItems="center">
                <Typography variant="h6" sx={{ color: "#888" }}>
                  +
                </Typography>
                <Typography variant="caption" sx={{ color: "#888" }}>
                  Upload Image
                </Typography>
              </Stack>
            </Button>
          )}
        </Box>
        <FormGroup
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <FormControl sx={{ width: "100%", flex: 1 }}>
            <TextField
              multiline
              rows={3}
              label="Text"
              value={tasteBudItem.text}
              onChange={(e) => handleTextChange(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", flex: 1, gap: 1 }}>
            <TextField
              label="Button Text"
              value={tasteBudItem.btn_text}
              onChange={(e) => handleButtonTextChange(e.target.value)}
            />
            <FormControl>
              <InputLabel>Button Link</InputLabel>
              <Select
                label="Button Link"
                value={tasteBudItem.btn_url}
                onChange={(e) => handleButtonUrlChange(e.target.value)}
              >
                {contentUrls.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
        </FormGroup>
      </Stack>
    </Paper>
  );
};

export default TasteBudForm;
