import React, { useEffect, useState } from "react";
import { styled, Box, IconButton, Typography, Stack, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { uploadFile } from "../../../../api/FileUploadAPI";
import { AWS_URL, MAX_FILE_SIZE } from "../../../../configs/constants";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface ImagePreviewProps {
  onImageUrlChange: (url: string) => void;
  value?: string; // Add an optional `value` prop
}

const ImagePreview = ({ onImageUrlChange , value }: ImagePreviewProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar("File size is too large. Please upload a smaller file.", { variant: "error" });
      return;
    }

    try {
      const uploadedFile = await uploadFile(file);
      if (uploadedFile) {
        const imageUrl = `${AWS_URL}/${uploadedFile}`;
        setImagePreview(imageUrl);
        onImageUrlChange(uploadedFile);
        enqueueSnackbar("File uploaded successfully!", { variant: "success" });
      }
    } catch (error: any) {
      enqueueSnackbar("Failed to upload the file. Please try again.", { variant: "error" });
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    onImageUrlChange("");
  };

  useEffect(() => {
    if (value) {
      const imageUrl =`${value}`;
      setImagePreview(imageUrl);
    }
  }, [value]);

  return (
    <Stack direction="column" sx={{ border: "1px solid #e0e0e0", p: 2, width: "100%" , height:"200px" , justifyContent:"center"}}>
      <Typography>Cover Image</Typography>
      <Box sx={{ display: "flex" }}>
        {imagePreview ? (
          <Box
            sx={{
              width: 150,
              height: 150,
              border: "1px dashed #ccc",
              position: "relative",
              backgroundColor: "#f5f5f5",
            }}
          >
            <img
              src={imagePreview}
              alt="Preview"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
            <IconButton
              size="small"
              onClick={handleRemoveImage}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "rgba(0, 0, 0, 0.5)",
                color: "white",
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <Box
            sx={{
              width: 100,
              height: 100,
              border: "1px dashed #ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Button
              component="label"
              variant="outlined"
              sx={{ height: "100%", width: "100%" }}
            >
              +
              <VisuallyHiddenInput type="file" accept="image/*" onChange={handleFileChange} />
            </Button>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default ImagePreview;
