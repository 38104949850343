import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IAssetItems, IAssets } from "../../../../models/IAssets";
import { getAssetByIdAPI, updateAssetAPI } from "../../../../api/AssetAPI";
import VideoForm from "./VideoForm";

const VideoEdit: React.FC = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [banner, setBanner] = useState<IAssets | null>(null);
  const [bannerItems, setBannerItems] = useState<IAssetItems[]>([]);

  useEffect(() => {
    let isMounted = true;

    if (id) {
      setIsLoading(true);
      getAssetByIdAPI(id)
        .then((data) => {
          if (isMounted) {
            setBanner(data);
            setBannerItems(data.items);
          }
        })
        .catch(() => {
          if (isMounted) {
            enqueueSnackbar("Failed to fetch video data.", {
              variant: "error",
            });
          }
        })
        .finally(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [id, enqueueSnackbar]);

  const updateBannerItems = (updatedItem: IAssetItems, index: number) => {
    setBannerItems((prevItems) =>
      prevItems.map((item, idx) => (idx === index ? updatedItem : item))
    );
  };

  const handleSubmit = async () => {
    if (!bannerItems.length || !id) {
      enqueueSnackbar("Please add at least one banner item.", {
        variant: "warning",
      });
      return;
    }

    try {
      setIsSubmitting(true);
      await updateAssetAPI(id, {
        placement: "video",
        items: bannerItems,
        is_active: banner?.is_active,
      });
      enqueueSnackbar("Video updated successfully!", { variant: "success" });
      navigate(-1);
    } catch (error) {
      enqueueSnackbar("Failed to update video.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto" }}>
      <Stack direction="row" alignItems="center" spacing={2} mb={4}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Edit Video</Typography>
      </Stack>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Upload Video
        </Typography>

        <VideoForm
          bannerItem={bannerItems[0]}
          updateBannerItems={(item) => updateBannerItems(item, 0)}
          bannerId={0}
        />
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting || !bannerItems.length}
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Update Video"}
        </Button>
      </Stack>
    </Box>
  );
};

export default VideoEdit;
