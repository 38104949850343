import {
  Box,
  FormGroup,
  MenuItem,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Button,
  Typography,
  FormControl,
  SelectChangeEvent,
  Paper,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/material";
import { IAssetItems } from "../../../../models/IAssets";
import { ChangeEvent, useState } from "react";
import { uploadFileAPI } from "../../../../api/APIBanners";
import { useContentUrls, CONTENT_POSITION_OPTIONS, AWS_URL } from "../../../../configs/constants";

interface BannerFormProps {
  bannerItems: IAssetItems;
  updateBannerItems: (bannerItems: IAssetItems, id: string) => void;
  bannerId: string;
  removeBannerItem: (id: string) => void;
}

const BannerForm = ({
  bannerItems: initialBannerItems,
  updateBannerItems,
  bannerId,
  removeBannerItem,
}: BannerFormProps) => {
  const [bannerItem, setBannerItem] = useState<IAssetItems>(initialBannerItems);
  const contentUrls = useContentUrls();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      try {
        const selectedFiles = Array.from(event.target.files);
        const uploadedFile = await uploadFileAPI(selectedFiles[0]);
        const updatedItem = { ...bannerItem, asset: uploadedFile };
        setBannerItem(updatedItem);
        updateBannerItems(updatedItem, bannerId);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleRemoveImage = () => {
    const updatedItem = { ...bannerItem, asset: "" };
    setBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedItem = { ...bannerItem, [name]: value };
    setBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    const updatedItem = { ...bannerItem, [name]: value };
    setBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  const handleRemoveBannerItem = () => {
    removeBannerItem(bannerId);
  };

  if (!bannerItem) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        width: "100%",
      }}
    >
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <Box
            sx={{
              width: 200,
              height: 200,
              border: "1px dashed #ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              backgroundColor:
                bannerItem.asset === "" ? "transparent" : "#f0f0f0",
              cursor: "pointer",
              borderRadius: 1,
            }}
          >
            {bannerItem.asset !== "" ? (
              <>
                <img
                  src={`${AWS_URL}/${bannerItem.asset}`}
                  alt="Banner"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
                <IconButton
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  onClick={handleRemoveImage}
                >
                  <HighlightOffIcon />
                </IconButton>
              </>
            ) : (
              <Button
                variant="outlined"
                component="label"
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 1,
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
                <Typography variant="h6" sx={{ color: "#888" }}>
                  +
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            flex: 1,
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              label="Header Text"
              fullWidth
              name="text"
              value={bannerItem.text}
              onChange={handleChange}
            />
            <input
              type="color"
              style={{ width: "75px", height: "50px" }}
              name="text_color"
              value={bannerItem.text_color}
              onChange={handleChange}
            />
            <TextField
              label="Header Text Color"
              placeholder="#000000"
              name="text_color"
              value={bannerItem.text_color}
              onChange={handleChange}
            />
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              label="Button Text"
              fullWidth
              name="btn_text"
              value={bannerItem.btn_text}
              onChange={handleChange}
            />
            <FormControl fullWidth>
              <InputLabel>Button Link</InputLabel>
              <Select
                label="Button Link"
                name="btn_url"
                value={bannerItem.btn_url}
                onChange={handleSelectChange}
              >
                {contentUrls.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <FormControl fullWidth>
            <InputLabel>Content Placement</InputLabel>
            <Select
              label="Content Placement"
              name="position"
              value={bannerItem.position}
              onChange={handleSelectChange}
            >
              {CONTENT_POSITION_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <IconButton onClick={handleRemoveBannerItem}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </FormGroup>
    </Paper>
  );
};

export default BannerForm;
