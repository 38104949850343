import { useParams } from "react-router-dom";
import useSWR from "swr";
import { PATH_NAME } from "../../../../configs/pathName";
import { generalFetcher } from "../../../../helpers/Fetchers/fetchers";
import { Box, Button, Divider, FormControl, Grid, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import CollectionTextFieldGroup from "./CollectionTextFieldGroup";
import { ICollection } from "../../../../models/ICollection";
import { useEffect, useState } from "react";
import { updateCollectionAPI } from "../../../../api/CollectionAPI";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ImagePreview from "../Sku/ImagePreview";
import { AWS_URL } from "../../../../configs/constants";

const EditCollection = () => {
  const { id } = useParams<{ id: string }>();
  const { data: collection, isValidating } = useSWR(
    id ? `${PATH_NAME.API_ADMIN_COLLECTION_BY_ID(id)}` : null,
    generalFetcher
  );
  const navigate = useNavigate();

  const [formData, setFormData] = useState<ICollection | null>(null);

  useEffect(() => {
    if (collection) {
      setFormData(collection);
    }
  }, [collection]);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleImageUrlChange = (url: string) => {
    const fileName = url.split("/").pop() || url;
    setFormData((prev) => (prev ? { ...prev, banner_url: fileName } : null));
  };

  const handelSave = async () => {
    if (!id || !formData) return;
    await updateCollectionAPI(id, formData);
    enqueueSnackbar("Collection updated successfully!", { variant: "success" });
    navigate(`/product/collections`);
  };

  const handleCancel = () => {
    navigate(`/product/collections`);
  };

  if (isValidating && !formData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box p={3}>

      <Grid item xs={10}>
        <Typography variant="h6">Edit Collection</Typography>
      </Grid>

      <Divider sx={{ my: 2 }} />
      
      <Grid container spacing={2} alignItems="center">

      <CollectionTextFieldGroup
        label="ID"
        name="id"
        value={formData?.id || ""}
        disabled
        onChange={handleTextFieldChange}
      />
      
      <CollectionTextFieldGroup
        label="Name"
        value={formData?.name || ""}
        name="name"
        onChange={handleTextFieldChange}
      />
    
      <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
        <Grid item xs={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Banner Image        
          </Typography>
        </Grid>

        <Grid item xs>
          <FormControl fullWidth margin="none" size="small">
            <ImagePreview
              onImageUrlChange={handleImageUrlChange}
              value={
                formData?.banner_url
                  ? `${AWS_URL}/${formData.banner_url}`
                  : ''
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <CollectionTextFieldGroup
        label="Priority"
        value={formData?.priority || ""}
        name="priority"
        onChange={handleTextFieldChange}
      />
      <Grid item container xs={12} justifyContent="flex-end">
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color="inherit" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handelSave}>
            Save
          </Button>
        </Stack>
      </Grid>
    </Grid>
    </Box>
  );
};

export default EditCollection;
