import { Box, Typography } from "@mui/material";
import { useNavigate, useLocation, Outlet, Navigate } from "react-router-dom";
import ContentBasicTab from "./ContentBasicTab";

const HomePageContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    {
      label: "Banners",
      value: "banner",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Announcement",
      value: "announcement",
    },
    {
      label: "TasteBud",
      value: "tastebud",
    },
  ];

  // Get current tab from path
  const currentTab = location.pathname.split("/")[3] || "banner";

  const handleTabChange = (newValue: string) => {
    navigate(`/Content/HomePageContent/${newValue}`);
  };

  // Redirect to banner tab if no tab is selected
  if (location.pathname === "/Content/HomePageContent") {
    return <Navigate to="/Content/HomePageContent/banner" replace />;
  }

  return (
    <Box>
      <Box sx={{ padding: 0, border: "1px solid #dddddd" }}>
        <div className="TitleSection">
          <Typography variant="h6" sx={{ padding: 2 }}>
            Home Page Content
          </Typography>
        </div>
        <ContentBasicTab
          tabs={tabs}
          currentTab={currentTab}
          onTabChange={handleTabChange}
        />
        <Box sx={{ p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePageContent;
