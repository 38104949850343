import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, TextField, Button, IconButton, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getSkuDetailsBySku, updateSkuDetails } from '../../../../../api/GetAllSkuAPI';
import { enqueueSnackbar } from 'notistack';
import { NutritionHeaderInfo, NutritionItem, NutritionFooter } from '../../../../../models/ISku';

const NutritionFact: React.FC = () => {
  const { sku } = useParams<{ sku: string }>();
  const { data } = useSWR(
    sku ? `getSkuDetailsBySku/${sku}` : null,
    () => (sku ? getSkuDetailsBySku(sku) : null)
  );

  const [nutritionHeader, setNutritionHeader] = useState<NutritionHeaderInfo>({
    servingsPerContainer: 0,
    servingSize: '',
    servingUnit: '',
    calories: 0
  });
  const [nutrients, setNutrients] = useState<NutritionItem[]>([]);
  const [nutritionFooter, setNutritionFooter] = useState<NutritionFooter>({ text: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (data?.result?.sku_details?.nutrition) {
      try {
        const nutritionData = data.result.sku_details.nutrition;
        if (typeof nutritionData === 'string') {
          const parsed = JSON.parse(nutritionData);
          setNutritionHeader({ ...parsed.header });
          setNutrients(parsed.nutrients || []);
          setNutritionFooter(parsed.footer || { text: '' });
        } else {
          setNutritionHeader({ ...nutritionData.header });
          setNutrients(nutritionData.nutrients || []);
          setNutritionFooter(nutritionData.footer || { text: '' });
        }
      } catch (error) {
        console.error('Error parsing nutrition data:', error);
      }
    }
  }, [data]);  

  const addMainNutrient = () => {
    setNutrients([
      ...nutrients,
      {
        id: Date.now().toString(),
        name: '',
        grams: 0,
        dailyValue: 0,
        subNutrients: []
      }
    ]);
  };

  const updateMainNutrient = (id: string, field: keyof NutritionItem, value: any) => {
    setNutrients(nutrients.map(ing => {
      if (ing.id === id) {
        return {
          ...ing,
          [field]: value
        };
      }
      return ing;
    }));
  };

  const removeMainNutrient = (id: string) => {
    setNutrients(nutrients.filter(ing => ing.id !== id));
  };

  const addSubNutrient = (mainId: string) => {
    setNutrients(nutrients.map(ing => {
      if (ing.id === mainId) {
        return {
          ...ing,
          subNutrients: [
            ...ing.subNutrients,
            { name: '', grams: 0 }
          ]
        };
      }
      return ing;
    }));
  };

  const updateSubNutrient = (mainId: string, index: number, field: keyof typeof nutrients[0]['subNutrients'][0], value: any) => {
    setNutrients(nutrients.map(ing => {
      if (ing.id === mainId) {
        const newSubNutrients = [...ing.subNutrients];
        newSubNutrients[index] = {
          ...newSubNutrients[index],
          [field]: value
        };
        return {
          ...ing,
          subNutrients: newSubNutrients
        };
      }
      return ing;
    }));
  };

  const removeSubNutrient = (mainId: string, subIndex: number) => {
    setNutrients(nutrients.map(ing => {
      if (ing.id === mainId) {
        return {
          ...ing,
          subNutrients: ing.subNutrients.filter((_, index) => index !== subIndex)
        };
      }
      return ing;
    }));
  };

  const handleHeaderChange = (field: keyof NutritionHeaderInfo, value: any) => {
    setNutritionHeader(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleFooterChange = (value: string) => {
    setNutritionFooter({ text: value });
  };

  const handleSave = async () => {
    if (!sku) {        
        return;
    }

    setIsSubmitting(true);
    try {
      // Format the nutrition data in JSONB structure
      const nutritionData = {
        header: {
          servingsPerContainer: Number(nutritionHeader.servingsPerContainer),
          servingSize: nutritionHeader.servingSize,
          servingUnit: nutritionHeader.servingUnit,
          calories: Number(nutritionHeader.calories)
        },
        nutrients: nutrients.map(nutrient => ({
          id: nutrient.id,
          name: nutrient.name,
          grams: Number(nutrient.grams),
          dailyValue: nutrient.dailyValue ? Number(nutrient.dailyValue) : null,
          subNutrients: nutrient.subNutrients.map(sub => ({
            name: sub.name,
            grams: Number(sub.grams),
            dailyValue: sub.dailyValue ? Number(sub.dailyValue) : null
          }))
        })),
        footer: {
          text: nutritionFooter.text
        }
      };

      const updatedData = {
        ...data.result.sku_details,
        nutrition: nutritionData
      };

      // Log the formatted data for verification
      console.log('Nutrition data:', JSON.stringify(nutritionData, null, 2));
      
      const response = await updateSkuDetails(sku, updatedData);
      console.log('Update response:', response);

      enqueueSnackbar("Nutrition facts updated successfully!", { variant: "success" });
    } catch (error) {
      console.error('Error saving nutrition facts:', error);
      enqueueSnackbar("Failed to update nutrition facts.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPreview = () => {
    return (
      <Paper 
        sx={{ 
          p: 3, 
          maxWidth: 400, 
          margin: '0 auto',
          border: '2px solid black',
          borderRadius: 1,
          mt: 4
        }}
      >
        <Typography 
          variant="h5" 
          sx={{ 
            fontWeight: 'bold',
            borderBottom: '1px solid black',
            pb: 1,
          }}
        >
          NUTRITION FACTS
        </Typography>

        <Typography sx={{ py: 1, fontSize: '14px' }}>
          {nutritionHeader.servingsPerContainer} servings per container
        </Typography>
        
        <Typography 
          sx={{ 
            fontWeight: 'bold', 
            fontSize: '16px',
            borderBottom: '8px solid black',
            pb: 1
          }}
        >
          Serving size {nutritionHeader.servingSize} {nutritionHeader.servingUnit}
        </Typography>

        <Box sx={{ 
          borderBottom: '4px solid black',
          py: 1
        }}>
          <Typography sx={{ fontSize: '14px' }}>
            Amount Per Serving
          </Typography>
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography sx={{ fontSize: '28px', fontWeight: 'bold', lineHeight: 1 }}>
              Calories
            </Typography>
            <Typography sx={{ fontSize: '36px', fontWeight: 'bold', lineHeight: 1 }}>
              {nutritionHeader.calories}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end',
          borderBottom: '4px solid black',
          py: 1,
          mb: 1
        }}>
          <Typography sx={{ 
            fontWeight: 'bold',
            fontSize: '14px'
          }}>
            % Daily Value*
          </Typography>
        </Box>

        {/* Nutrients List */}
        {nutrients.map((nutrient, index) => (
          <Box key={nutrient.id}>
            <Box 
              sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ddd',
                py: 1
              }}
            >
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{nutrient.name}</Typography>
                <Typography>{nutrient.grams}g</Typography>
              </Box>
              <Typography>
                {nutrient.dailyValue ? `${nutrient.dailyValue}%` : ''}
              </Typography>
            </Box>

            {/* Sub-nutrients */}
            {nutrient.subNutrients.map((subNutrient, subIndex) => (
              <Box 
                key={subIndex}
                sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #ddd',
                  py: 0.5,
                  pl: 3
                }}
              >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Typography>{subNutrient.name}</Typography>
                  <Typography>{subNutrient.grams}g</Typography>
                </Box>
                <Typography>
                  {subNutrient.dailyValue ? `${subNutrient.dailyValue}%` : ''}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}

        {/* Footer */}
        <Typography 
          sx={{ 
            fontSize: '12px', 
            mt: 2,
            borderTop: '1px solid #ddd',
            pt: 1,
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            maxWidth: '100%'
          }}
        >
          {nutritionFooter.text}
        </Typography>
      </Paper>
    );
  };

  const renderNutritionHeader = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Servings Per Container"
            type="number"
            value={nutritionHeader.servingsPerContainer}
            onChange={(e) => handleHeaderChange('servingsPerContainer', Number(e.target.value))}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Serving Size"
            value={nutritionHeader.servingSize}
            onChange={(e) => handleHeaderChange('servingSize', e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Serving Unit"
            value={nutritionHeader.servingUnit}
            onChange={(e) => handleHeaderChange('servingUnit', e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Calories"
            type="number"
            value={nutritionHeader.calories}
            onChange={(e) => handleHeaderChange('calories', Number(e.target.value))}
            size="small"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <Grid container spacing={4}>
        {/* Left Side - Accordions */}
        <Grid item xs={12} md={6}>
          {/* Nutrition Header Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                borderBottom: '1px solid #ddd',
                borderTop: '1px solid #ddd',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                NUTRITION FACTS HEADER
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper sx={{ p: 3 }}>
                {renderNutritionHeader()}
              </Paper>
            </AccordionDetails>
          </Accordion>

          {/* Nutrients Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                borderBottom: '1px solid #ddd',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                NUTRIENTS
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper sx={{ p: 3 }}>
                {nutrients.map((nutrient) => (
                  <Box key={nutrient.id} sx={{ mb: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Nutrient Name"
                          value={nutrient.name}
                          onChange={(e) => updateMainNutrient(nutrient.id, 'name', e.target.value)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          label="Grams"
                          type="number"
                          value={nutrient.grams}
                          onChange={(e) => updateMainNutrient(nutrient.id, 'grams', Number(e.target.value))}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          label="Daily Value %"
                          type="number"
                          value={nutrient.dailyValue || ''}
                          onChange={(e) => updateMainNutrient(nutrient.id, 'dailyValue', e.target.value ? Number(e.target.value) : undefined)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton 
                          onClick={() => removeMainNutrient(nutrient.id)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                    {/* Sub-nutrients */}
                    {nutrient.subNutrients.map((subNutrient, index) => (
                      <Box key={index} sx={{ ml: 4, mt: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Sub-nutrient Name"
                              value={subNutrient.name}
                              onChange={(e) => updateSubNutrient(nutrient.id, index, 'name', e.target.value)}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              fullWidth
                              label="Grams"
                              type="number"
                              value={subNutrient.grams}
                              onChange={(e) => updateSubNutrient(nutrient.id, index, 'grams', Number(e.target.value))}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              fullWidth
                              label="Daily Value %"
                              type="number"
                              value={subNutrient.dailyValue || ''}
                              onChange={(e) => updateSubNutrient(nutrient.id, index, 'dailyValue', e.target.value ? Number(e.target.value) : undefined)}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton 
                              onClick={() => removeSubNutrient(nutrient.id, index)}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}

                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => addSubNutrient(nutrient.id)}
                      sx={{ ml: 4, mt: 1 }}
                      size="small"
                    >
                      Add Sub-nutrient
                    </Button>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addMainNutrient}
                  sx={{ mt: 2 }}
                >
                  Add Main Nutrient
                </Button>
              </Paper>
            </AccordionDetails>
          </Accordion>

          {/* Footer Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                borderBottom: '1px solid #ddd',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                NUTRITION FACTS FOOTER
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper sx={{ p: 3 }}>
                <TextField
                  fullWidth
                  label="Footer Text"
                  multiline
                  rows={4}
                  value={nutritionFooter.text}
                  onChange={(e) => handleFooterChange(e.target.value)}
                  size="small"
                />
              </Paper>
            </AccordionDetails>
          </Accordion>

          {/* Save Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save Changes"}
            </Button>
          </Box>
        </Grid>

        {/* Right Side - Preview */}
        <Grid item xs={12} md={6}>
          {renderPreview()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NutritionFact;
