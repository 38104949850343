import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

interface VideoConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (id: number | null) => void;
  id: number | null;
}

const VideoConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  id,
}: VideoConfirmationDialogProps) => {
  const handleConfirm = () => {
    onConfirm(id);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"Confirm Deletion"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this video?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoConfirmationDialog;
