import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { IAssets, IAssetItems } from "../../../../models/IAssets";
import { getAssetByIdAPI, updateAssetAPI } from "../../../../api/AssetAPI";
import TasteBudForm from "./TasteBudForm";

const generateId = () => Math.random().toString(36).substr(2, 9);

const initialTasteBudItems: IAssetItems = {
  id: generateId(),
  asset: "",
  text: "",
  text_color: "",
  btn_text: "",
  btn_url: "",
  position: "",
};

const TasteBudEdit: React.FC = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [tasteBudItems, setTasteBudItems] = useState<IAssetItems[]>([
    initialTasteBudItems,
  ]);
  const [banner, setBanner] = useState<IAssets | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchBanner = async () => {
      if (!id) return;

      try {
        setIsLoading(true);
        const data = await getAssetByIdAPI(id);
        if (isMounted) {
          setBanner(data);
          const itemsWithIds =
            data.items?.map((item: IAssetItems) => ({
              ...item,
              id: item.id || generateId(),
            })) || [];
          setTasteBudItems(itemsWithIds);
        }
      } catch (error) {
        if (isMounted) {
          enqueueSnackbar("Failed to fetch taste bud data", {
            variant: "error",
          });
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchBanner();

    return () => {
      isMounted = false;
    };
  }, [id, enqueueSnackbar]);

  const updateTasteBudItem = (item: IAssetItems, id: string) => {
    setTasteBudItems((prevItems) =>
      prevItems.map((prevItem, index) => (prevItem.id === id ? item : prevItem))
    );
  };

  const addTasteBudItem = () => {
    setTasteBudItems((prevItems) => [
      ...prevItems,
      { ...initialTasteBudItems, id: generateId() },
    ]);
  };

  const removeTasteBudItem = (idToRemove: string) => {
    setTasteBudItems((prevItems) =>
      prevItems.filter((item) => item.id !== idToRemove)
    );
  };

  const handleSubmit = async () => {
    if (!banner || !id) return;

    if (tasteBudItems.length === 0) {
      enqueueSnackbar("Please provide at least one item.", {
        variant: "warning",
      });
      return;
    }

    try {
      setIsSubmitting(true);
      await updateAssetAPI(id, {
        items: tasteBudItems,
        placement: "tastebud",
        is_active: banner.is_active,
      });
      enqueueSnackbar("Taste bud updated successfully!", {
        variant: "success",
      });
      navigate(-1);
    } catch (error) {
      enqueueSnackbar("Failed to update taste bud.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto" }}>
      <Stack direction="row" alignItems="center" spacing={2} mb={4}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Edit Taste Bud</Typography>
      </Stack>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Upload Items ({tasteBudItems.length}/3)
        </Typography>

        <Grid container spacing={1}>
          {tasteBudItems.map((item) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={item.id}
              sx={{ width: "100%" }}
            >
              <TasteBudForm
                tasteBudItem={item}
                removeTasteBudItem={removeTasteBudItem}
                updateTasteBudItem={updateTasteBudItem}
                tasteBudId={item.id || ""}
              />
            </Grid>
          ))}
          {tasteBudItems.length < 3 && (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #ccc",
                  borderRadius: 1,
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  backgroundColor: "#f0f0f0",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
                onClick={addTasteBudItem}
              >
                <IconButton>
                  <AddIcon />
                </IconButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting || tasteBudItems.length === 0}
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Save Changes"}
        </Button>
      </Stack>
    </Box>
  );
};

export default TasteBudEdit;
