import {
  Box,
  Button,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  Stack,
  TextField,
  Typography,
  InputLabel,
  Paper,
  SelectChangeEvent,
} from "@mui/material";
import {
  AWS_URL,
  CONTENT_URLS_OPTIONS,
  MAX_VIDEO_SIZE,
} from "../../../../configs/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { IAssetItems } from "../../../../models/IAssets";
import { useState, ChangeEvent } from "react";
import { uploadFileAPI } from "../../../../api/APIBanners";
import { enqueueSnackbar } from "notistack";

interface VideoFormProps {
  bannerItem: IAssetItems;
  updateBannerItems: (bannerItems: IAssetItems, id: number) => void;
  bannerId: number;
}

const VideoForm = ({
  bannerItem,
  bannerId,
  updateBannerItems,
}: VideoFormProps) => {
  const [localBannerItem, setLocalBannerItem] =
    useState<IAssetItems>(bannerItem);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFiles = Array.from(event.target.files);

      if (selectedFiles[0].size > MAX_VIDEO_SIZE) {
        enqueueSnackbar("File size is too large", { variant: "error" });
        return;
      }

      const uploadedFile = await uploadFileAPI(selectedFiles[0]);
      const updatedItem = { ...localBannerItem, asset: uploadedFile };
      setLocalBannerItem(updatedItem);
      updateBannerItems(updatedItem, bannerId);
    }
  };

  const handleRemoveVideo = () => {
    const updatedItem = { ...localBannerItem, asset: "" };
    setLocalBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedItem = { ...localBannerItem, [name]: value };
    setLocalBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    const updatedItem = { ...localBannerItem, [name]: value };
    setLocalBannerItem(updatedItem);
    updateBannerItems(updatedItem, bannerId);
  };

  if (!localBannerItem) return null;

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        padding: 2,
      }}
    >
      {localBannerItem.asset !== "" ? (
        <Box sx={{ position: "relative", width: "fit-content" }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: 600,
              borderRadius: 1,
              overflow: "hidden",
              bgcolor: "background.paper",
              boxShadow: 1,
            }}
          >
            <video
              src={`${AWS_URL}/${localBannerItem.asset}`}
              controls
              style={{ width: "100%", maxHeight: "400px" }}
            />
            <IconButton
              onClick={handleRemoveVideo}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                bgcolor: "background.paper",
                "&:hover": { bgcolor: "background.paper" },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Button
          variant="outlined"
          component="label"
          sx={{
            width: 300,
            height: 125,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <input
            type="file"
            accept="video/*"
            hidden
            onChange={handleFileChange}
          />
          <Typography variant="body1">Click to Upload Video</Typography>
          <Typography variant="caption" color="textSecondary">
            Maximum size: {(MAX_VIDEO_SIZE / (1024 * 1024)).toFixed(0)}MB
          </Typography>
        </Button>
      )}
      <Stack direction="row" spacing={1} sx={{ width: "100%", flex: 1 }}>
        <TextField
          multiline
          rows={4}
          label="Header Text"
          name="text"
          value={localBannerItem.text}
          onChange={handleChange}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <TextField
            fullWidth
            label="Button Text"
            value={localBannerItem.btn_text}
            name="btn_text"
            onChange={handleChange}
          />
          <FormControl fullWidth>
            <InputLabel>Button Url</InputLabel>
            <Select
              name="btn_url"
              value={localBannerItem.btn_url}
              onChange={handleSelectChange}
            >
              {CONTENT_URLS_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Paper>
  );
};

export default VideoForm;
