import React from "react";
import { Box, Button, TextField, CircularProgress, Grid, Typography } from "@mui/material";
import { ISkuDetails, ISkuFormProps } from "../../../models/ISku";
import { ICollection } from "../../../models/ICollection";
import CollectionSelection from "../../features/ProductManagement/Sku/CollectionSelection";
import ImagePreview from "../../features/ProductManagement/Sku/ImagePreview";
import AdditionalImagePreview from "../../features/ProductManagement/Sku/AdditionalImagePreview";
import HQSku from "../../features/ProductManagement/Sku/HQSku";
import BadgeSelection from "../../features/ProductManagement/Sku/BadgeSelection";
import { IBadge } from "../../../models/IBadge";

const SkuForm: React.FC<ISkuFormProps> = ({
  skuForm,
  onSave,
  onCancel,
  isLoading,
}) => {
  const [formValues, setFormValues] = React.useState<ISkuDetails>({
    ...skuForm,
    batches: skuForm.batches?.map(Number) || [],
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]:
        name === "sales_ranking" ||
        name === "price" ||
        name === "average_rating" ||
        name === "review_count"
          ? Number(value) || 0
          : name === "additional_image_urls" ||
            name === "batches" ||
            name === "feature_logos"
          ? value.split(",")
          : value,
    }));
  };

  const handleCollectionChange = (collections: ICollection[]) => {
    const collectionIds = collections.map((c) => c.id);
    setFormValues((prev) => ({ ...prev, collections: collectionIds }));
  };

  const handleBadgeChange = (badges: IBadge[]) => {
      const mappedBadges = badges.map((c) => c.id);
      setFormValues((prev) => ({ ...prev, batches: mappedBadges }));
    };

  const handleImageUrlChange = (url: string) => {
    setFormValues((prev) => ({ ...prev, image_url: url }));
  };

  const handleImageUrlsChange = (urls: string[]) => {
    setFormValues((prev) => ({ ...prev, additional_image_urls: urls }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formValues);
  };

  return (
    <form onSubmit={handleSubmit}>
    <HQSku/>
      <Grid item xs={3}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ marginY: 2 }}>Sku Details</Typography>
        <Typography sx={{fontSize:'12px', color:'red'}}>Size: 550px * 440px</Typography>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <ImagePreview onImageUrlChange={handleImageUrlChange} />
        </Grid>

        <Grid item xs={9} sx={{ marginBottom: 2 }}>
          <AdditionalImagePreview
            onImageUrlsChange={handleImageUrlsChange}
            value={formValues.additional_image_urls || []}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <TextField
              label="Short Name"
              name="short_name"
              value={formValues.short_name}
              onChange={handleChange}
              fullWidth
              required
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              fullWidth
              required
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="SKU"
              name="sku_sku"
              value={formValues.sku_sku}
              onChange={handleChange}
              fullWidth
              required
              size="small"
              disabled={!!skuForm.sku_sku}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Sales Ranking"
              name="sales_ranking"
              value={formValues.sales_ranking}
              onChange={handleChange}
              type="number"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <CollectionSelection
              value={formValues.collections}
              onChange={handleCollectionChange}
            />
          </Grid>
          <Grid item xs={6}>
            <BadgeSelection
              value={formValues.batches}
              onChange={handleBadgeChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Price"
              name="price"
              value={formValues.price}
              onChange={handleChange}
              type="number"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              fullWidth
              size="small"
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={3} style={{ display: 'none' }}>
            <TextField
              label="Image URL"
              name="image_url"
              value={formValues.image_url}
              onChange={handleChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={3} style={{ display: 'none' }}>
            <TextField
              label="Additional Image URLs"
              name="additional_image_urls"
              value={formValues.additional_image_urls.join(",")}
              onChange={handleChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: 2 }}>Ingredients</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Ingredients"
              name="ingredients"
              value={formValues.ingredients}
              onChange={handleChange}
              fullWidth
              size="small"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: 2 }}>Allergen</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Allergen"
              name="allergen"
              value={formValues.allergen}
              onChange={handleChange}
              fullWidth
              size="small"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            marginTop: 2,
            gap: 1,
          }}
        >
          <Button variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={20} />}
          >
            {isLoading ? "Creating..." : "Create"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default SkuForm;
