import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IAssetItems } from "../../../../models/IAssets";
import BannerForm from "./BannerForm";
import { addAssetAPI } from "../../../../api/AssetAPI";

const generateId = () => Math.random().toString(36).substr(2, 9);

const initialBannerItems: IAssetItems[] = [
  {
    id: generateId(),
    asset: "",
    text: "",
    text_color: "#000000",
    btn_text: "",
    btn_url: "/",
    position: "left",
  },
];

const BannerAdd = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [bannerItems, setBannerItems] =
    useState<IAssetItems[]>(initialBannerItems);

  const handleSubmit = async () => {
    if (bannerItems.length === 0) {
      enqueueSnackbar("Please add at least one banner item.", {
        variant: "warning",
      });
      return;
    }

    try {
      setIsLoading(true);

      await addAssetAPI({ items: bannerItems, placement: "banner" });
      enqueueSnackbar("Banner added successfully!", { variant: "success" });
      navigate(-1);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      enqueueSnackbar("Failed to add banner.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateBannerItems = (bannerItems: IAssetItems, id: string) => {
    setBannerItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? bannerItems : item))
    );
  };

  const addBannerItems = () => {
    setBannerItems((prevItems) => [
      ...prevItems,
      { ...initialBannerItems[0], id: generateId() },
    ]);
  };

  const removeBannerItem = (idToRemove: string) => {
    if (bannerItems.length === 1) {
      enqueueSnackbar("Please add at least one banner item.", {
        variant: "warning",
      });
      return;
    }
    setBannerItems((prevItems) =>
      prevItems.filter((item) => item.id !== idToRemove)
    );
  };

  return (
    <Box sx={{ margin: "0 auto", width: "100%" }}>
      <Stack direction="row" alignItems="center" spacing={2} mb={4}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Add New Banner</Typography>
      </Stack>

      <Stack
        direction="column"
        spacing={1}
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        {bannerItems.map((item) => (
          <BannerForm
            key={item.id}
            bannerItems={item}
            updateBannerItems={updateBannerItems}
            bannerId={item.id || ""}
            removeBannerItem={removeBannerItem}
          />
        ))}
      </Stack>

      <Button variant="outlined" onClick={addBannerItems} sx={{ mt: 2 }}>
        Add Banner
      </Button>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading || bannerItems.length === 0}
        >
          {isLoading ? <CircularProgress size={24} /> : "Add Banner"}
        </Button>
      </Stack>
    </Box>
  );
};

export default BannerAdd;
