import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

interface CalenderPickerProps {
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  label: string;
}

const CalenderPicker: React.FC<CalenderPickerProps> = ({
  value,
  onChange,
  label,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["year", "month", "day"]}
        label={label}
        value={value}
        onChange={onChange}
        sx={{ mb: 2, mt: 2 }}
      />
    </LocalizationProvider>
  );
};

export default CalenderPicker;
