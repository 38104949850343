import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VideoForm from "./VideoForm";
import { IAssetItems } from "../../../../models/IAssets";
import { addAssetAPI } from "../../../../api/AssetAPI";

const initialVideoItems: IAssetItems[] = [
  {
    asset: "",
    text: "",
    text_color: "#000000",
    btn_text: "",
    btn_url: "left",
    position: "",
  },
];

const VideoAdd: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bannerItems, setBannerItems] =
    useState<IAssetItems[]>(initialVideoItems);

  const handleSubmit = async () => {
    if (bannerItems.length === 0) {
      enqueueSnackbar("Please add at least one banner item.", {
        variant: "warning",
      });
      return;
    }

    try {
      setIsSubmitting(true);
      await addAssetAPI({ placement: "video", items: bannerItems });
      enqueueSnackbar("Video added successfully!", { variant: "success" });
      navigate(-1);
    } catch (error) {
      enqueueSnackbar("Failed to add video.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateBannerItems = (updatedItem: IAssetItems, index: number) => {
    setBannerItems((prevItems) =>
      prevItems.map((item, idx) => (idx === index ? updatedItem : item))
    );
  };

  return (
    <Box sx={{ margin: "0 auto", width: "100%" }}>
      <Stack direction="row" alignItems="center" spacing={2} mb={4}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Add New Video</Typography>
      </Stack>

      <Box sx={{ mb: 4, width: "100%" }}>
        <Typography variant="subtitle1" gutterBottom>
          Video Details
        </Typography>

        <VideoForm
          bannerItem={bannerItems[0]}
          updateBannerItems={(item) => updateBannerItems(item, 0)}
          bannerId={0}
        />
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting || bannerItems.length === 0}
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Add Video"}
        </Button>
      </Stack>
    </Box>
  );
};

export default VideoAdd;
