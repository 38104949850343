export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const AWS_URL = process.env.REACT_APP_AWS_URL;
export const MAX_FILE_SIZE = 500 * 1024; // 500kb
export const MAX_VIDEO_SIZE = 2000000; // 2000000 KB
export const CONTENT_POSITION_OPTIONS = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
  { value: "center", label: "Center" },
];

export const CONTENT_URLS_OPTIONS = [
  { value: "/", label: "Home" },
  { value: "/about", label: "About" },
  { value: "/contact", label: "Contact" },
];
