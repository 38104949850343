import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSkuDetailsBySku, updateSkuDetails } from "../../../../../api/GetAllSkuAPI";
import { Typography, Box, Button, TextField, Grid } from "@mui/material";
import useSWR from "swr";
import { enqueueSnackbar } from "notistack";
import CollectionSelection from "../CollectionSelection";
import { ICollection } from "../../../../../models/ICollection";
import ImagePreview from "../ImagePreview";
import AdditionalImagePreview from "../AdditionalImagePreview";
import { IBadge } from "../../../../../models/IBadge";
import BadgeSelection from "../BadgeSelection";

const AWS_URL = process.env.REACT_APP_AWS_URL;

const EditSkuDetails: React.FC = () => {
  const { sku } = useParams<{ sku: string }>();
  const navigate = useNavigate();
  const { data, error, isLoading } = useSWR(
    sku ? `getSkuDetailsBySku/${sku}` : null,
    () => (sku ? getSkuDetailsBySku(sku) : null)
  );

  const [formData, setFormData] = useState<Record<string, any>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCollectionChange = (collections: ICollection[]) => {
    const mappedCollections = collections.map((c) => c.id);
    setFormData((prev) => ({ ...prev, collections: mappedCollections }));
  };

  const handleBadgeChange = (badges: IBadge[]) => {
    const mappedBadges = badges.map((c) => c.id.toString());
    setFormData((prev) => ({ ...prev, batches: mappedBadges }));
  };

  const handleImageUrlChange = (url: string) => {
    const fileName = url.split("/").pop() || url;
    setFormData((prev) => ({ ...prev, image_url: fileName }));
  };

  const handleImageUrlsChange = (urls: string[]) => {
    setFormData((prev) => ({ ...prev, additional_image_urls: urls }));
  };

  const handleSave = async () => {
    if (!sku) return;

    setIsSubmitting(true);

    try {
      const updatedData = {
        ...skuDetails,
        ...formData,
      };

      await updateSkuDetails(sku, updatedData);
      enqueueSnackbar("SKU details updated successfully!", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to update SKU details.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    if (sku) {
      navigate(`/product/skus/`);
    }
  };

  if (isLoading) return <div>Loading SKU details...</div>;
  if (error) return <div>Error loading SKU details</div>;

  const skuDetails = data?.result?.sku_details;

  const readOnlyFields = ["sku_sku"];
  const excludedFields = [
    "id",
    "is_deleted",
    "created_at",
    "updated_at",
    "deleted_at",
    "created_by",
    "updated_by",
    "deleted_by",
    "image_url",
    "additional_image_urls",
    
    "nutrition",
    "storage_info",
    "feature_logos",
    
    "weight",
    "size",
    "average_rating",
    "review_count",
  ];

  if (!skuDetails) {
    return <div>No SKU details available.</div>;
  }

  return (
    <Box sx={{ maxWidth: "100%", padding: 2 }}>

      <Typography variant="h6" sx={{ marginBottom: 2 }}>Edit SKU Details</Typography>
      <Typography sx={{fontSize:'12px', color:'red'}}>Size: 550px * 440px</Typography>

      <Grid container spacing={0}>
        <Grid item xs={3}>          
        <ImagePreview 
          onImageUrlChange={handleImageUrlChange}
          value={
            formData.image_url
              ? `${AWS_URL}/${formData.image_url}`
              : skuDetails.image_url
              ? `${AWS_URL}/${skuDetails.image_url}`
              : undefined 
          }
        />
        </Grid>
        <Grid item xs={9} sx={{ marginBottom: 2 }}>
          <AdditionalImagePreview
            onImageUrlsChange={handleImageUrlsChange}
            value={formData.additional_image_urls || skuDetails.additional_image_urls || []}
          />
        </Grid>

          <Grid container spacing={1}>
            
            {Object.keys(skuDetails)
              .filter((key) => !excludedFields.includes(key))
              .map((key) =>{
                const isMultilineField = ["ingredients", "allergen"].includes(key.toLowerCase());
                return isMultilineField ? (
                  
                  <Grid item xs={12} key={key}>
                    <TextField
                      label={key.replace(/_/g, " ")}
                      name={key}
                      value={formData[key] ?? skuDetails[key] ?? ""}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      disabled={readOnlyFields.includes(key)}
                      multiline
                      rows={4}
                    />
                  </Grid>
                ) : key === "description" ? (
                    <Grid item xs={12} key={key}>
                      <TextField
                        label={key.replace(/_/g, " ")}
                        name={key}
                        value={formData[key] ?? skuDetails[key] ?? ""}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        disabled={readOnlyFields.includes(key)}
                        multiline
                        rows={4}
                      />
                    </Grid>
                  ) : key === "batches" ? (
                    <Grid item xs={6} key={key}>
                      <BadgeSelection
                        value={data.result.sku_details.batches}
                        onChange={handleBadgeChange}
                      />
                    </Grid>
                  ) : key !== "collections" ? (
                    <Grid item xs={12} sm={6} key={key}>
                      <TextField
                        label={key.replace(/_/g, " ")}
                        name={key}
                        value={formData[key] ?? skuDetails[key] ?? ""}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        disabled={readOnlyFields.includes(key)}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} key={key}>
                      <CollectionSelection
                        value={data.result.sku_details.collections}
                        onChange={handleCollectionChange}
                      />
                    </Grid>
                    
                  );
              })}
            </Grid>
          </Grid>
        <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={isSubmitting}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </Button>
            </Box>
    </Box>
  );
};

export default EditSkuDetails;
