import React, { useRef, useState } from "react";
import { Box, Button, Typography, Grid, InputLabel } from "@mui/material";
import {
  getCsvMachinePaynowOrders,
  getCsvPayments,
  getCsvStoreCreditBalance,
  upload2C2PSettlement,
  uploadPayNowSettlement,
} from "../../../api/FinanceAPI";
import { CSVLink } from "react-csv";
import { Dayjs } from "dayjs";
import CalenderPicker from "../../../helpers/DatePicker/CalenderPicker";

const FinancePage: React.FC = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const timezone = 8; // Hardcoded timezone

  const [csvData, setCsvData] = useState<any[]>([]);
  const [csvHeaders, setCsvHeaders] = useState<any[]>([]);
  const [csvFilename, setCsvFilename] = useState<string>("");

  const csvLinkRef = useRef<any>(null);

  // Helper function for date validation
  const isDateValid = (date: Dayjs | null) => date && date.isValid();

  // Handlers
  const handleDownloadPayments = async () => {
    if (!isDateValid(startDate) || !isDateValid(endDate)) {
      alert("Please enter valid dates in the format 'yyyy-mm-dd'.");
      return;
    }
    try {
      const apiData = await getCsvPayments(
        startDate?.format("YYYY-MM-DD") || "",
        endDate?.format("YYYY-MM-DD") || "",
        timezone
      );
      const csvData = apiData.split("\n");
      const headers = csvData.shift()?.split(","); // Extract headers from the first row
      if (!headers) {
        console.error("Error parsing CSV: No headers found");
        return;
      }
      const mappedHeaders = headers.map((header: string) => {
        return {
          label: header.trim(),
          key: header.trim(),
        };
      });

      // Convert an object, the key is the header, the value is the data
      const data = csvData.map((row: string) => {
        const values = row.split(",");
        return headers.reduce((acc: any, header: string, index: number) => {
          acc[header.trim()] = values[index]?.trim() || "";
          return acc;
        }, {});
      });

      const filename = `payments_${startDate?.format(
        "YYYY-MM-DD"
      )}_${endDate?.format("YYYY-MM-DD")}.csv`;

      await Promise.all([
        setCsvData(data),
        setCsvHeaders(mappedHeaders),
        setCsvFilename(filename),
      ]);

      setTimeout(() => {
        if (csvLinkRef.current) {
          csvLinkRef.current.link.click();
        }
      }, 100);

      // // Create a CSVLink with the extracted headers and remaining data
      // return (
      //   <CSVLink data={data} headers={mappedHeaders} filename="payments.csv">
      //     <Button variant="contained" onClick={handleDownloadPayments}>
      //       Download Payments
      //     </Button>
      //   </CSVLink>
      // );
    } catch (error) {
      console.error("Error downloading payments:", error);
    }
  };

  const handleDownloadPayNowOrders = async () => {
    if (!isDateValid(startDate) || !isDateValid(endDate)) {
      alert("Please enter valid dates in the format 'yyyy-mm-dd'.");
      return;
    }
    try {
      const apiData = await getCsvMachinePaynowOrders(
        startDate?.format("YYYY-MM-DD") || "",
        endDate?.format("YYYY-MM-DD") || "",
        timezone
      );
      // const csvData = apiData.result.split("\n"); // Split response for CSV formatting
      const csvData = apiData.split("\n");
      const filename = `paynow_orders_${startDate?.format(
        "YYYY-MM-DD"
      )}_${endDate?.format("YYYY-MM-DD")}.csv`;
      downloadFile(csvData, filename);
    } catch (error) {
      console.error("Error downloading PayNow orders:", error);
    }
  };

  const handleDownloadStoreCreditBalance = async () => {
    try {
      const apiData = await getCsvStoreCreditBalance();
      // const csvData = apiData.result.split("\n"); // Split response for CSV formatting
      const csvData = apiData.split("\n"); // Split response for CSV formatting
      const filename = `store_credit_balance.csv`;
      downloadFile(csvData, filename);
    } catch (error) {
      console.error("Error downloading store credit balance:", error);
    }
  };

  const handleUploadFile = async (uploadApi: (file: File) => Promise<void>) => {
    if (!file) {
      alert("Please select a file before uploading.");
      return;
    }
    const validExtensions = ["csv", "xls", "xlsx"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (!fileExtension || !validExtensions.includes(fileExtension)) {
      alert("Only Excel (.xlsx, .xls) or CSV files are allowed.");
      return;
    }

    try {
      await uploadApi(file);
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // Helper for downloading files
  const downloadFile = async (data: string[], filename: string) => {
    // Modified signature
    const blob = new Blob([data.join("\n")], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link); // Required for some browsers
    link.click();
    document.body.removeChild(link); // Clean up
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Finance Management
      </Typography>

      {/* Date Range */}
      <Box mb={3}>
        <CalenderPicker
          label="Start Date"
          value={startDate}
          onChange={(e) => setStartDate(e)}
        />
        <CalenderPicker
          label="End Date"
          value={endDate}
          onChange={(e) => setEndDate(e)}
        />
      </Box>

      {/* Download Section */}
      <Typography variant="h6">Download Reports</Typography>
      <Grid container spacing={2} mb={3}>
        <Grid item>
          <Button variant="contained" onClick={handleDownloadPayments}>
            Download Payments
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleDownloadPayNowOrders}>
            Download PayNow Orders
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleDownloadStoreCreditBalance}
          >
            Download Store Credit Balance
          </Button>
        </Grid>
      </Grid>

      {/* Upload Section */}
      <Typography variant="h6">Upload Files</Typography>
      <Box mb={3}>
        <InputLabel htmlFor="file-upload">Choose a file to upload</InputLabel>
        <input
          id="file-upload"
          type="file"
          onChange={(e) => setFile(e.target.files?.[0] || null)}
          style={{ marginBottom: "1rem" }}
        />
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => handleUploadFile(upload2C2PSettlement)}
            >
              Upload 2C2P Settlement
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => handleUploadFile(uploadPayNowSettlement)}
            >
              Upload PayNow Settlement
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CSVLink
        data={csvData}
        headers={csvHeaders}
        filename={csvFilename}
        ref={csvLinkRef}
        style={{ display: "none" }}
      />
    </Box>
  );
};

export default FinancePage;
