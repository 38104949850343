import React, { useState } from "react";
import { Box, Button, Divider, FormControl, Grid, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import CollectionTextFieldGroup from "./CollectionTextFieldGroup";
import { createCollectionAPI } from "../../../../api/CollectionAPI";
import { ICollectionCreate } from "../../../../models/ICollection";
import { useNavigate } from "react-router-dom";
import ImagePreview from "../Sku/ImagePreview";
import { AWS_URL } from "../../../../configs/constants";

const defaultFormData: ICollectionCreate = {
  name: "",
  banner_url: "",
  priority: 0,
};

const AddCollection: React.FC = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<ICollectionCreate>(defaultFormData);

  const handleImageUrlChange = (url: string) => {
    setFormData((prev) => ({ ...prev, banner_url: url }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await createCollectionAPI(formData);
      navigate('/product/collections');
      enqueueSnackbar("Collection created successfully!", {
        variant: "success",
      });
      setFormData(defaultFormData);
    } catch (error) {
      enqueueSnackbar("Failed to create collection.", { variant: "error" });
    }
  };

  const handleCancel = () => {
    navigate("/product/collections");
  };

  return (
    <Box p={3}>
      <Typography variant="h6" gutterBottom>
        Create Collection
      </Typography>

      <Divider sx={{ my: 2 }} />
      <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
        <Grid item xs={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Banner Image        
          </Typography>
          <Typography sx={{fontSize:'12px', color:'red'}}>970px * 250px</Typography>

        </Grid>

        <Grid item xs>
          <FormControl fullWidth margin="none" size="small">
            <ImagePreview
              onImageUrlChange={handleImageUrlChange}
              value={
                formData?.banner_url
                  ? `${AWS_URL}/${formData.banner_url}`
                  : ''
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        <CollectionTextFieldGroup
          label="Name"
          value={formData.name}
          name="name"
          onChange={handleChange}
        />
        
        <CollectionTextFieldGroup
          label="Priority"
          name="priority"
          value={formData.priority}
          onChange={handleChange}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button variant="contained" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AddCollection;
