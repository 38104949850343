import axiosInstance from '../api/axiosInstance';
import { PATH_NAME } from '../configs/pathName';

export const getCsvPayments = async (startDate: string, endDate: string, timezone: number) => {
    const url = PATH_NAME.API_ADMIN_GET_ALL_PAYMENTS(startDate, endDate, timezone);
    const response = await axiosInstance.get(url);
    return response.data;
  };
  
  export const getCsvMachinePaynowOrders = async (startDate: string, endDate: string, timezone: number) => {
    const url = PATH_NAME.API_ADMIN_GET_ALL_PAYNOW_ORDERS(startDate, endDate, timezone);
    const response = await axiosInstance.get(url);
    return response.data;
};

export const getCsvStoreCreditBalance = async () => {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_GET_ALL_STORE_CREDIT_BALANCE);
    return response.data;
};

export const upload2C2PSettlement = async (file: File): Promise<void> => {
    const formData = new FormData();
    formData.append("file", file);
    await axiosInstance.post(PATH_NAME.API_ADMIN_POST_2C2P_SETTLEMENT, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
};
  
  export const uploadPayNowSettlement = async (file: File): Promise<void> => {
    const formData = new FormData();
    formData.append("file", file);
    await axiosInstance.post(PATH_NAME.API_ADMIN_POST_PAYNOW_SETTLEMENT, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
};